import './App.css';
import './assets/css/cni.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NavbarW from './components/core/NavbarW'
import Main from './components/core/Main'
import DigitalTwins from './components/core/DigitalTwins'
import Robotics from './components/core/Robotics'
import BlockchainExplorer from './components/core/BlockchainExplorer'
import FooterW from './components/core/FooterW';
import BioMarker from './components/core/BioMarker';
import Blockchain from './components/core/Blockchain';

function App() {
  return (
    <BrowserRouter>
      <NavbarW />
      <Switch>            
            <Route exact path="/main" component={Main} />
            <Route exact path="/robotics" component={Robotics} />
            <Route exact path="/digital_twins" component={DigitalTwins} />
            <Route exact path="/blockchain_explorer" component={BlockchainExplorer} />
            <Route exact path="/blockchain" component={Blockchain} />
            <Route exact path="/biomarker" component={BioMarker} />
            <Route path="/" component={Main} />
      </Switch>   
      <FooterW />
    </BrowserRouter>
  );
}

export default App;

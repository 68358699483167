// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";


import metaverse from "../../assets/img/robotics/metaverse2.jpg"

export default function Main() {
    return (
        <>

        <section className="padding-top-bottom-left-right section-transparent09">
          <Container>
              <br />
              <br />
              <div className="col s12 m6">
                <h2>
                The next decade Metaverse Experience and Digital Transformation
                </h2>
              </div>
              <br />
              <Container>                
                <p style={{color: "blue", fontSize: '25px'}}> 
                  Revolutionizing how de-identified data across the globe will be exchanged and monetized.
                </p>
                <p style={{color: "blue", fontSize: '25px'}}> 
                  Creator of the world's first Metaverse City.
                </p>                
              </Container>
              <br />
              
            </Container>
          </section>

          <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>                    
                    <Row className="row-grid align-items-center">

                        {/* <Col md="1"></Col>                         */}
                        
                        <Col md="12">
                            {/* <Card className="bg-default shadow border-0"> */}
                                <CardImg
                                    alt="..."
                                    src={metaverse}
                                    top
                                />
                            {/* </Card> */}
                        </Col>
                        {/* <Col md="3">                        </Col> */}
                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
                <br/>
                <br/>
            </section>


            <section className="padding-top-bottom-left-right section-transparent09">
              <Container>
                <br />
                <br />
                <div className="col s12 m6">
                  <h2>
                  Connect to CNI Metaverse
                  </h2>
                </div>
                <br />

                <Container>
                  <p className="lead mt-4 text-align-left">
                  
                    <p>
                      <span className="thick">
                      General Enquiries
                      </span>
                    </p>
                    <br />     
                    <p>
                      <span>
                      E-mail:
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </span> 
                      <span>tech@cni-metaverse.com</span>
                    </p>             
                    <p>
                      <span>
                      Hotline:
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </span> 
                      <span>(852) 65769551</span>
                    </p>
                      <p>
                      <span>
                      Mail:                          
                      </span> 
                      <span>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                      ROOM 29-33, 5/F, Beverley Commercial Centre, 87-105 Chatham Road, Tsim Sha Tsui, HK                            
                      </span>
                    </p>
                    <p>
                      <span>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                
                      </span>
                    </p>
                    
                  </p>
                </Container>
              </Container>

              <br/>
              <br/>
              
            </section>
            
        </>
    )
}
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
import biomarker_measuringscreen from "../../assets/img/biomarker/biomarker_measuringscreen_20220328.png"
import biomarker_model2 from "../../assets/img/biomarker/biomarker_model2_20220328.jpg"
import biomarker_usbcharging from "../../assets/img/biomarker/biomarker_usbcharging_20220328.jpg"
import biomarker_waterproofip68 from "../../assets/img/biomarker/biomarker_waterproofip68.png"
import biomarker_webui from "../../assets/img/biomarker/biomarker_webui_20220328.png"

export default function BioMarker(){
    return (
        <div>
            {/* <section className="section text-align-justify"> */}
            <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light">
                <Container>
                        <br/>
                        <br/>
                        <Row className="row-grid align-items-center">
                            <div className="col s12 m6">
                            <h2>
                                BioMarker
                            </h2>
                            </div>
                        </Row>
                        <Row className="row-grid align-items-center">
                            <Col md="12">
                                <div >                                            
                                    <p className="lead mt-4 text-align-justify">
                                        BioMarker aims ...
                                        <ul>
                                            <li> to provide high quality health care outcomes for family / community members who have caring one(s) who inevitable / want to live independently.</li>
                                            <li> to provide high quality health care outcomes for vulnerable people who inevitably / want to live independently.</li>
                                        </ul>
                                    </p>                                
                                </div>
                            </Col>                        
                        </Row>
                        <br/>
                </Container>            
            </section>
            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <div className="col s12 m6">
                        <h3>
                            BioMarker 1.0 --- CovidCare
                        </h3>
                        </div>
                    </Row>
                    <Row className="row-grid align-items-center">
                        <Col md="12">
                            <div >                                            
                                <p className="lead mt-4 text-align-justify">
                                    24 hours continuous Bio-Data monitoring
                                </p> 
                                (Body Temperature, Heart Rate, Blood Pressure, Blood Oxygen)                                                               
                            </div>
                        </Col>                        
                    </Row>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <Col md="1"></Col>
                        <Col md="4">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={biomarker_model2}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="2"></Col>
                        <Col md="4">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={biomarker_usbcharging}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="1"></Col>                        
                    </Row>

                    <br/>
                    <br/>
                    
                    <Row className="row-grid align-items-center">
                        <Col md="3"></Col>
                        <Col md="6">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={biomarker_waterproofip68}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="3"></Col>                        
                    </Row>

                    <br/>                    

                    <Row className="row-grid align-items-center">
                        <Col md="12">
                            <div >                                            
                                <p className="lead mt-4 text-align-justify">
                                    Web-based monitoring portal for Single / Multi User(s)
                                </p> 
                                --- for Family / Community members who have caring one(s)
                            </div>
                        </Col>                        
                    </Row>
                    <br/>

                    <Row className="row-grid align-items-center">
                        <Col md="1"></Col>
                        <Col md="2">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={biomarker_measuringscreen}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="6">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={biomarker_webui}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="1"></Col>                        
                    </Row>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <Col md="12">
                            <div >                                            
                                <p className="lead mt-4 text-align-justify">
                                    Add-on Functions
                                </p> 
                                Alert on Health Situation
                                <ul>
                                    <li>Abnormal Body Temperature</li>
                                    <li>Abnormal Blood Pressure Level</li>
                                    <li>Abnormal Heart Rate</li>
                                    <li>Abnormal Blood Oxygen Level</li>
                                </ul>
                            </div>
                        </Col>                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

import uman_ur_cad_20220309 from "../../assets/img/robotics/uman_ur_cad_20220309.jpg"
import uman_ur_real_20220309 from "../../assets/img/robotics/uman_ur_real_20220309.jpg"
import uman_ur_cad2d_20220309 from "../../assets/img/robotics/uman_ur_cad2d_20220309.jpg"
import digitaltwins_1 from "../../assets/img/robotics/digitaltwins_1_20220309.png"
import digitaltwins_2 from "../../assets/img/robotics/digitaltwins_2_20220309.png"

export default function DigitalTwins() {
    return (
        <>
            {/* <section className="section text-align-justify"> */}
            <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light">
                <Container>
                    <br/>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <div className="col s12 m6">
                        <h2>
                            Our Definition of Digital Twins
                        </h2>
                        </div>
                    </Row>
                    <Row className="row-grid align-items-center">

                        <Col md="12">
                            <div >                                            
                                <p className="lead mt-4 text-align-justify">
                                Simply put, a digital twin is a virtual copy of a physical entity. A digital twin uses software to simulate down to the individual devices, tools, processes, systems, and even people or locations. Doing so avoids the expenses, resources, potential risks and time for real-world experiments – though real-world testing is needed to validate the results of digital twins.                                
                                </p>                                
                                <p className="lead mt-4 text-align-justify">
                                    CNI Metaverse can help customers to develop their own customized Digital Twins.
                                </p>                                
                            </div>
                        </Col>                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>


            {/* <section className="section text-align-justify"> */}
            <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light">
                <Container>
                    <br/>
                    <br/>
                    
                    <Row className="row-grid align-items-center">

                        {/* <Col md="2"></Col>                         */}
                        
                        <Col md="12">
                            {/* <Card className="bg-default shadow border-0"> */}
                                <CardImg
                                    alt="..."
                                    src={digitaltwins_1}
                                    top
                                />
                            {/* </Card> */}
                        </Col>
                        {/* <Col md="2">

                        </Col> */}
                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>

                    <br/>
                    <br/>
                    <br/>
                    <br/>

                </Container>
            </section>

            <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light">
                <Container>
                    <br/>
                    <br/>
                    
                    <Row className="row-grid align-items-center">

                        {/* <Col md="2"></Col>                         */}
                        
                        <Col md="12">
                            {/* <Card className="bg-default shadow border-0"> */}
                                <CardImg
                                    alt="..."
                                    src={digitaltwins_2}
                                    top
                                />
                            {/* </Card> */}
                        </Col>
                        {/* <Col md="2">

                        </Col> */}
                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>

            
        </>
    )
}
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class FooterW extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          <hr />
          <Container className="container-lg">
            {/* <Row>
              <Col className="mb-5 mb-md-0" md="6">
                <Card className="card-lift--hover shadow border-0">
                  <Link to="/landing-page">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/landing.jpg")}
                    />
                  </Link>
                </Card>
              </Col>
              <Col className="mb-5 mb-lg-0" md="6">
                <Card className="card-lift--hover shadow border-0">
                  <Link to="/profile-page">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/profile.jpg")}
                    />
                  </Link>
                </Card>
              </Col>
            </Row> */}
          </Container>
          <Container>
            {/* <Row className="row-grid align-items-center my-md">
              <Col lg="6">
                <h3 className="text-primary font-weight-light mb-2">
                  Thank you for supporting us!
                </h3>
                <h4 className="mb-0 font-weight-light">
                  Let's get in touch on any of these platforms.
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/creativetim"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/creativetim"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="dribbble"
                  href="https://dribbble.com/creativetim"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-dribbble" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href="https://github.com/creativetimofficial"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-github" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Github
                </UncontrolledTooltip>
              </Col>
            </Row> */}

            <Row className="align-items-center justify-content-md-between">
              {/* <Col md="1">
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/STSTWinCaverns/"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
              </Col> */}
              <Col md="1">
                <div className="">
                  {/* <a
                    href="https://www.facebook.com/STSTWinCaverns/"
                    target="_blank"
                  >
                    <img
                      className="photo_format_facebook"
                      // src={facebook_icon}
                      alt="facebook link"
                    />
                  </a> */}
                </div>
              </Col>
              <Col md="3">
                {/* <div className="">
                  <a
                    href="https://www.dsd.gov.hk/TC/Home/index.html"
                    target="_blank"
                  >
                    <img
                      className="photo_format_dsd"
                      // src={dsd_icon}
                      alt="Drainage Services Department"
                    />
                  </a>
                </div> */}
              </Col>
              <Col md="1">
                {/* <div className="">
                  <a href="https://aecom.com/" target="_blank">
                    <img
                      className="photo_format_aecom"
                      // src={aecom_icon}
                      alt="AECOM"
                    />
                  </a>
                </div> */}
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end text-grey">
                  {/* <NavItem>
                    <NavLink
                      // href="https://aecom-portal2.web.app/index"
                      target="_blank"
                    >
                      Drainage Services Department
                    </NavLink>
                  </NavItem> */}
                  CNI Technology Company Limited
                  © Copyright
                </Nav>
              </Col>
            </Row>
            <br/>
          </Container>
        </footer>
      </>
    );
  }
}

export default FooterW;

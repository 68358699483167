import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../redux/store/store";
// import { logout } from "../../redux/auth/action";
import { Link } from 'react-router-dom'

export default function NavbarW() {
//   const isAuthenticated = useSelector(
//     (state: RootState) => state.auth.isAuthenticated
//   );
//   const dispatch = useDispatch();
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <span>
            {"\u00A0"} {"\u00A0"} CNI Metaverse            
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link as={Link} to="/main">
              Main
            </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link as={Link} to="/digital_twins">
              DigitalTwins
            </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link as={Link} to="/robotics">
              Robotics
            </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            {/* <Nav.Link as={Link} to="/blockchain_explorer"> */}
            <Nav.Link as={Link} to="/blockchain">
              Blockchain
            </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link as={Link} to="/biomarker">
              BioMarker
            </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            
            <Nav.Link href="#"> </Nav.Link>
            <Nav.Link href="#"> </Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
          </Nav>

        </Navbar.Collapse>
      </Navbar>
  );
}

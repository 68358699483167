import { 
    Container, 
    Row, 
    Col,
    Card,
    CardImg,
    CardTitle,
    CardText,
    CardImgOverlay,
    CardGroup,
    CardBody,
    CardSubtitle,
    Button,
} from "reactstrap";
import blockchain_icon from "../../assets/img/blockchain/blockchain_icon.png"
import smart_contract_icon from "../../assets/img/blockchain/smart_contract_icon.png"
import immutability_icon from "../../assets/img/blockchain/immutability_icon.png"
import traceability_icon from "../../assets/img/blockchain/traceability_icon.png"

export default function Blockchain() {
    return (
        <div>
            <section className="padding-top-bottom-left-right section-transparent09">
                <Container>                    

                    <Row className="row-grid align-items-center">
                        <Col md="8">
                            <br />
                            <br />
                            <div className="col s12 m6">
                                <h2>
                                Blockchain
                                </h2>
                            </div>
                            <br />
                            <Container>                
                                <p style={{color: "grey", fontSize: '25px'}}> 
                                Tokenization of assets into digitized valuables
                                </p>
                                <p style={{color: "black", fontSize: '23px'}}> 
                                Tokenization is the process by which an asset can be converted into digital token on a blockchain. It can be programmed to incorporate regulations, transactions, and right of possession. This token can also be further divided or fractionalized. Tokenization is capable to apply to application such as loyalty programme.
                                </p>                
                            </Container>
                            <br />
                        </Col>
                        <Col md="4">
                            <div 
                                // className="bg-light border" 
                                style={{ 
                                    "height" : "20em", 
                                    "display": "flex", 
                                    "justify-content": "center", 
                                    "align-items": "center"
                                }}
                            >                                            
                                <img          
                                    src={blockchain_icon}
                                    alt="Blockchain"
                                    height="60%"
                                />  
                            </div>                            
                        </Col>                        
                    </Row>
                </Container>
            </section>

            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    
                    <Row className="row-grid align-items-center">
                        <Col md="12">
                            <br />
                            <br />
                            <div className="col s12 m6">
                                <h2>
                                    Our Hybrid Chain Solution
                                </h2>
                            </div>
                            <br />
                            <Container>                
                                <p style={{color: "grey", fontSize: '25px'}}> 
                                    Public Chain / Private Chain / Tokenization / NFT / Decentralizaed Data
                                </p>
                                <p style={{color: "black", fontSize: '23px'}}> 
                                    Build next-generation apps, launch blockchain-based infrastructure, and access the decentralized web with customized product suite.
                                </p>                
                            </Container>
                            <br />
                        </Col>                  
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="padding-top-bottom-left-right section-transparent09">
                <Container>                    
                    
                    <Row className="row-grid align-items-center">                                                
                        <CardGroup>
                            <Card>
                                <div 
                                    // className="bg-light border" 
                                    style={{ 
                                        "height" : "20em", 
                                        "display": "flex", 
                                        "justify-content": "center", 
                                        "align-items": "center"
                                    }}
                                >                                            
                                    <img          
                                        src={smart_contract_icon}
                                        alt="Smart Contract"  
                                        height="60%"      
                                    />  
                                </div> 
                                <CardBody>
                                    <CardTitle tag="h5">
                                        Smart Contract
                                    </CardTitle>
                                    <br/>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        Enable the predetermined conditions and regulations to be written and integrated into code.
                                    </CardSubtitle>                                
                                </CardBody>
                            </Card>
                            <Card>
                                <div 
                                    // className="bg-light border" 
                                    style={{ 
                                        "height" : "20em", 
                                        "display": "flex", 
                                        "justify-content": "center", 
                                        "align-items": "center"
                                    }}
                                >                                            
                                    <img          
                                        src={immutability_icon}
                                        alt="Immutability"    
                                        height="60%"    
                                    />  
                                </div> 
                                <CardBody>
                                    <CardTitle tag="h5">
                                        Immutability
                                    </CardTitle>
                                    <br/>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        The block contains data that cannot be altered under any circumstances without granted permission.
                                    </CardSubtitle>                                
                                </CardBody>
                            </Card>
                            <Card>
                                <div 
                                    // className="bg-light border" 
                                    style={{ 
                                        "height" : "20em", 
                                        "display": "flex", 
                                        "justify-content": "center", 
                                        "align-items": "center"
                                    }}
                                >                                            
                                    <img          
                                        src={traceability_icon}
                                        alt="Traceability"
                                        height="60%"
                                    />  
                                </div> 
                                <CardBody>
                                    <CardTitle tag="h5">
                                        Traceability
                                    </CardTitle>
                                    <br/>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        Each piece of data can be tracked down with ease and accuracy at any stage of the transaction and any part of the block.
                                    </CardSubtitle>                                    
                                </CardBody>
                            </Card>
                            </CardGroup>
                        
                    </Row>

                </Container>
            </section>
        </div>
    )
}

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

import uman_ur_cad_20220309 from "../../assets/img/robotics/uman_ur_cad_20220309.jpg"
import uman_ur_real_20220309 from "../../assets/img/robotics/uman_ur_real_20220309.jpg"
import uman_ur_cad2d_20220309 from "../../assets/img/robotics/uman_ur_cad2d_20220309.jpg"
import digitaltwins_1 from "../../assets/img/robotics/digitaltwins_1_20220309.png"
import digitaltwins_2 from "../../assets/img/robotics/digitaltwins_2_20220309.png"

export default function Robotics() {
    return (
        <>
            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <div className="col s12 m6">
                        <h2>
                            Robotics
                        </h2>
                        </div>
                    </Row>
                    <Row className="row-grid align-items-center">

                        <Col md="12">
                            <div >                                            
                                <p className="lead mt-4 text-align-justify">
                                    The mobile manipulator CNI-ATLR is a combination of the omnidirectional unmanned robotics platform with a lightweight robot arm.
                                </p>                                
                            </div>
                        </Col>                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>
                    <Row className="row-grid align-items-center">
                        <div className="col s12 m6">
                        <h2>
                            Our CNI-ATLR
                        </h2>
                        </div>
                    </Row>
                    <Row className="row-grid align-items-center">

                        <Col md="2"></Col>

                        <Col md="3">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={uman_ur_cad_20220309}
                                    top
                                />
                            </Card>
                        </Col>

                        <Col md="2"></Col>

                        <Col md="3">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={uman_ur_real_20220309}
                                    top
                                />
                            </Card>
                        </Col>
                        <Col md="2"></Col>
                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>
                    
                    <Row className="row-grid align-items-center">

                        <Col md="12">
                            <div >                                            
                    
                                <p className="lead mt-4 text-align-justify">

                                    With its Mecanum wheels, the CNI-ATLR can move seamlessly in any direction and easily relocate the integrated robot arm. Without complex manoeuvring and kinematic restrictions, the working area of the arm can be considerably enlarged.
                                    The CNI-ATLR can carry the robot arms of the most important manufacturers. Due to its large footprint and load capacity, which can handle loads of up to 10 kg.


                                    <br />
                                    <br />                                                

                                </p>
                            </div>
                        </Col>                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section text-align-justify">
            {/* <section className="section-bg-grey padding-top-bottom-left-right section-transparent09 bg_light"> */}
                <Container>
                    <br/>
                    <br/>                    
                    <Row className="row-grid align-items-center">

                        <Col md="3"></Col>                        
                        
                        <Col md="6">
                            {/* <Card className="bg-default shadow border-0"> */}
                                <CardImg
                                    alt="..."
                                    src={uman_ur_cad2d_20220309}
                                    top
                                />
                            {/* </Card> */}
                        </Col>
                        <Col md="3">

                        </Col>
                        
                    </Row>

                    <Row>
                        <Col> 
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </section>



            
            
        </>
    )
}